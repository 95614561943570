import {
  Box,
  Button,
  Card,
  Container,
  CssBaseline,
  Dialog,
  FormControl,
  Grid,
  Link,
  ThemeProvider,
  Typography,
  useTheme,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  TextField,
} from "@mui/material";
import axios from "axios";
import { Field, FormikProvider, useFormik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import assets from "../../../assets";
import { FormEmailTextField } from "../FormTextField";
import colorConfigs from "../../../configs/colorConfigs";
import versionConfig from "../../../configs/versionConfig";

axios.defaults.baseURL = window.env.API_URL;
const loginpage = "/auth/login";

function Copyright(props: any) {

  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      Bellylabs is registered trademarks of Bellylabs in many countries of the world.
    </Typography>
  );
}

export default function ForgottenPasswordCard() {
  const [showDialog, setShowDialog] = useState(false);

  const navigate = useNavigate();
  const theme = useTheme();

  const validationSchema = () => {
    return Yup.object().shape({
      email: Yup.string()
        .email("Invalid email format")
        .required("This field is required"),
    });
  };

  const handleClose = () => {
    setShowDialog(false);
    navigate("/login");
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: (values) => {
      const post_data = {
        email: values.email,
      };
      console.log(post_data);
      axios
        .post("api/auth/password-reset/", post_data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
      setShowDialog(true);
    },
  });

  return (
    <FormikProvider value={formik}>
    <div style={{ marginLeft: "auto", marginRight: "auto", paddingTop:"5%" }}>
        <div style={{display: "flex", justifyContent: "center"}}>
                    <Box
                    component="img"
                    sx={{
                    height: "50%",
                    width: "50%",
                    maxHeight: { xs: 433, md: 255 },
                    maxWidth: { xs: 550, md: 394 },
                    mb: 4,
                    }}
                    alt="Bellylabs logo"
                    src={assets.images.blLogo}
                    />
        </div>
        <form onSubmit={formik.handleSubmit}>
        <Card sx={{display:'flex', width: "40rem", justifyContent: 'center', boxShadow: 10, paddingBottom:"10%", borderRadius: 5}} >

            <ThemeProvider theme={theme}>
              <Container component="main" maxWidth="xs">
                <CssBaseline />
                {showDialog && (
                  <Dialog
                    open={showDialog}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      Password Recovery
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        {"We have sent an email to "}{" "}
                        <strong>{formik.values.email}</strong>
                        {" with instructions to reset your password. "}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose} color="primary" autoFocus>
                        {"Accept"}
                      </Button>
                    </DialogActions>
                  </Dialog>
                )}
                <Box
                  sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ mt: 1 }} />
                  <FormControl fullWidth>
                    <div className="form-group">
                      <Field
                        name="email"
                        type="text"
                        className="form-control"
                        label="Email"
                        component={FormEmailTextField}
                      />
                    {/* <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        autoComplete="email"
                        autoFocus
                    /> */}
                    </div>
                    <div className="form-group">
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt:3, mb: 2, width: "60%",  marginLeft: '20%', background: colorConfigs.buttons.bg, color: "white", textTransform: 'none', transition: 'transform 0.3s ease', fontWeight: "bold", '&:hover': { transform: 'scale(1.03)'}}}
                        >
                    Submit
                    </Button>
                    </div>
                  </FormControl>
                </Box>
                <Grid container>
                  <Grid item xs>
                    <Link href="/login" variant="body2">
                      Return to  Login
                    </Link>
                  </Grid>
                </Grid>
                <Typography variant="body2" color="text.secondary" align="center">
                    {versionConfig.version}
                </Typography>
              </Container>
            </ThemeProvider>
          </Card>
        </form>
      </div>
    </FormikProvider>
  );
}
