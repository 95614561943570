import { Alert, AlertTitle, Box, Button, Card, Container, CssBaseline, FormControl, Grid, Link, MenuItem, TextField, ThemeProvider, Typography, useTheme } from '@mui/material';
import axios from 'axios';
import { Field, FormikProvider, useFormik } from 'formik';
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from "yup";
import assets from '../../../assets';
import { FormEmailTextField, FormPasswordTextField } from '../FormTextField';
import colorConfigs from '../../../configs/colorConfigs';
import versionConfig from '../../../configs/versionConfig';

axios.defaults.baseURL = window.env.API_URL;
const loginpage = "/auth/login"

function Copyright(props: any) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            Bellylabs is registered trademarks of Bellylabs in many countries of the world.
        </Typography>
    );
};

export default function ResetPasswordCard() {
    const navigate = useNavigate();
    const theme = useTheme();

    const [errors, setErrors] = useState<string | null>(null);

    const validationSchema = Yup.object().shape({
        password1: Yup.string()
            .required("This field is required")
            .min(8, "Password must be at least 8 characters long")
            .matches(/[a-zA-Z]/, "Password must contain at least one letter")
            .matches(/\d/, "Password must contain at least one number"),
        password2: Yup.string()
            .required("This field is required")
            .oneOf([Yup.ref('password1')], "Passwords must match"),
    });

    var { uidb64, token } = useParams()
    console.log(uidb64, token)

    function getCookie(name: any) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                // Does this cookie string begin with the name we want?
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    const formik = useFormik({
        validateOnChange: false,
        validateOnBlur: false,
        initialValues: {
            password1: "",
            password2: "",
        },
        validationSchema,
        onSubmit: (values) => {
            const post_data = {
                new_password1: values.password1,
                new_password2: values.password2,
                uid: uidb64,
                token: token,
            }
            axios.post('/api/auth/password/reset-confirm/', post_data, {
                headers: {
                    "X-CSRFToken": getCookie('csrftoken'),
                }
            })
                .then(function (response) {
                    console.log(response);
                    navigate('/auth/login');
                })
                .catch(function (error) {
                    if (error.response?.data?.new_password2[0]) {
                        setErrors(error.response.data.new_password2[0])
                    }
                    if (error.response) {
                        console.error("Error data:", error.response.data);
                        console.error("Error status:", error.response.status);
                        console.error("Error headers:", error.response.headers);
                    } else if (error.request) {
                        console.error("Error request:", error.request);
                    } else {
                        console.error('Error', error.message);
                    }
                });
        }
    });

    return (
        <FormikProvider value={formik}>
            <div style={{ marginLeft: "auto", marginRight: "auto", paddingTop: "5%" }}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <Box
                        component="img"
                        sx={{
                            height: "50%",
                            width: "50%",
                            maxHeight: { xs: 433, md: 255 },
                            maxWidth: { xs: 550, md: 394 },
                            mb: 4,
                            pb: 6,
                            pt: 6
                        }}
                        alt="Bellylabs logo"
                        src={assets.images.blLogo}
                    />
                </div>
                <form onSubmit={formik.handleSubmit}>

                    <Card sx={{ display: 'flex', width: "100%", justifyContent: 'center', boxShadow: 10, paddingBottom: "10%", borderRadius: 5, margin: "auto" }} >
                        <ThemeProvider theme={theme}>
                            <Container component="main" maxWidth="xs">
                                <CssBaseline />
                                <Box
                                    sx={{
                                        marginTop: 8,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    {errors && (
                                        <Alert severity="error" style={{ marginTop: '16px' }}>
                                            <AlertTitle>{"Error"}</AlertTitle>
                                            {errors}
                                        </Alert>
                                    )}
                                    <FormControl fullWidth >
                                        <div className="form-group">
                                            <Field name="password1" label={"Password"} type="text" required className="form-control" component={FormPasswordTextField} />
                                        </div>
                                        <div className="form-group">
                                            <Field name="password2" label={"Confirm Password"} type="text" required className="form-control" component={FormPasswordTextField} />
                                        </div>
                                        <div className="form-group">
                                            <Button
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                sx={{ mt: 3, mb: 2, width: "60%", marginLeft: '20%', background: colorConfigs.buttons.bg, color: "white", textTransform: 'none', transition: 'transform 0.3s ease', fontWeight: "bold", '&:hover': { transform: 'scale(1.03)' } }}
                                            >
                                                Submit
                                            </Button>
                                        </div>
                                    </FormControl>
                                </Box>
                                <Grid container>
                                    <Grid item xs>
                                        <Link href="/auth/login" variant="body2">
                                            Return to Login
                                        </Link>
                                    </Grid>
                                </Grid>
                                <Typography variant="body2" color="text.secondary" align="center">
                                    {versionConfig.version}
                                </Typography>
                            </Container>
                        </ThemeProvider>
                    </Card>
                </form>
            </div>
        </FormikProvider>
    );
};  